
import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import { MarkerType, ShowIcons, Link } from "@/store/mapService";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "EditMap",
  data() {
    return {
      icon: "" as string,
      pin: "" as string,
      newTitle: "" as string,
      desc: "" as string,
      dialog: false as boolean,
      link: {
        title: "",
        url: ""
      } as Link,
      showIcons: ShowIcons.show as ShowIcons,
      markerType: MarkerType.date as MarkerType
    };
  },
  computed: {
    ...mapGetters(["currMap", "mapTitles"]),
    maps(): string {
      return this.mapTitles.join();
    }
  },
  mounted() {
    this.icon = this.currMap.icon;
    this.pin = this.currMap.pin;
    this.desc = this.currMap.description;
    this.link = this.currMap.link;
    this.showIcons = this.currMap.showIcons;
    this.markerType = this.currMap.markerType;
  },
  methods: {
    async update() {
      const allValid = await this.$validator.validateAll();

      if (allValid) {
        await this.$store.dispatch("updateMap", [
          this.currMap.title,
          this.icon,
          this.pin,
          this.newTitle,
          this.desc,
          this.link,
          this.showIcons
        ]);
        this.dialog = false;

        if (this.newTitle.length > 0) {
          this.$router.push(`/maps/${this.newTitle}`);
        }
      }
    },
    getMarkerTypeDate() {
      return MarkerType.date;
    },
    getMarkerTypeNumber() {
      return MarkerType.number;
    },
    getShowIcons() {
      return ShowIcons.show;
    },
    getNoShowIcons() {
      return ShowIcons.noShow;
    }
  }
});
